<template>

    <div>
        <el-backtop :right="40" :bottom="40" />
        <div v-loading="documentStore.loading"
             class="post">

            <TranslationConfig v-model="translationConfig"
                               @configChanged="saveConfig"
                               pageNamePrefix="Current document"
                               >
                <el-divider />
                <DocumentActions v-model="translationConfig" />
            </TranslationConfig>

            <DocumentStatistics v-if="documentStatisticsExist"
                                :documentName="documentName"
                                :documentInformation="documentInformation" />

            <div class="segmnets-editor-container">

                <SegmentEditor :key="pageKey + 'N'"
                               :documentConfiguration="documentData"
                               @reload="loadConfigurations"/>

                <div class="paging">
                    <el-pagination layout="prev, pager, next"
                                   :total="pagingStore.rowCount"
                                   :current-page="pagingStore.currentPageNumber"
                                   :default-page-size="pagingStore.pageSize"
                                   @current-change="goToPage" />
                </div>
            </div>

        </div>

        <HotkeysHandler />
    </div>

</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { documentStore } from '@/stores/documentStore'
    import { pagingStore } from '@/stores/pagingStore'

    import { documentService } from './documentService'

    import TranslationConfig from '@/components/TranslationConfig/TranslationConfig.vue'
    import DocumentStatistics from './DocumentStatistics.vue'
    import DocumentActions from './Actions/DocumentActions.vue'
    import SegmentEditor from './SegmentEditor.vue'

    import HotkeysHandler from './HotkeysHandler.vue'

    export default defineComponent({
        components: {
            TranslationConfig,
            DocumentActions,
            SegmentEditor,
            HotkeysHandler,
            DocumentStatistics,
        },
        data() {
            return {
                loading: false,
                pageKey: 0,
                documentService: new documentService(),
                documentData: null as any,
                documentName: '',
                translationConfig: null,
                documentInformation: null,
                reload: false,
                pagingStore: pagingStore(),
                documentStore: documentStore(),
            };
        },
        computed: {
            documentId(): number {
                return Number(this.$route.params.id);
            },
            documentStatisticsExist(): boolean {
                return this.documentInformation != null;
            },
        },
        watch: {
            documentId: {
                immediate: true,
                handler(newVal){
                    if (isNaN(newVal)){
                        return;
                    }

                    this.documentStore.setDocumntId(newVal);
                    this.pagingStore.resetState();
                    this.loadConfigurations();
                }
            },
            async 'documentStore.documentUpdate'(){
                this.pageKey++;
                await this.loadConfigurations();
            },
        },

        methods: {
            async loadConfigurations() {
                this.documentStore.startLoading();

                this.documentData = await this.documentService.loadConfigurations(this.documentId)

                this.translationConfig = this.documentData?.translationConfig;
                this.documentInformation = this.documentData?.documentInformation;
                this.documentName = this.documentData?.name;

                this.documentStore.stopLoading();
            },
            async saveConfig() {
                await this.documentService.saveConfig(this.documentId, this.translationConfig);
            },
            goToPage(pageNumebr: number) {
                this.pagingStore.goToPage(pageNumebr);
            },
        },

    });
</script>

<style lang="scss" scoped>
    .segmnets-editor-container {
        padding: 20px 4px;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        border: 1px solid #e4e7ed;
        background-color: #ffffff;
        color: #303133;
        transition: 0.3s;

        .paging {
            display: flex;
            justify-content: space-around;
            margin-top: 20px;
        }
    }
</style>