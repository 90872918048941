import axios, { AxiosError } from 'axios'
import { notificationService } from '@/services/notificationService'

export class apiClient {
    notificationService: notificationService;

    constructor() {
        this.notificationService = new notificationService();
    }

    async apiGet(url: string, params = {}) {
        try {
            const responce = await axios.get(url, params);
            return this.formatResponse(responce)
        }
        catch (error: any) {
            return this.handleError(error as AxiosError);
        }
    }

    async apiPost(url: string, data = {}, unsafeCall: boolean = false) {
        try {
            const responce = await axios.post(url, data);
            return this.formatResponse(responce)
        }
        catch (error: any) {
            if (unsafeCall) {
                throw error as AxiosError;
            }

            return this.handleError(error as AxiosError);
        }
    }

    formatResponse(response: any) {
        const content = response ? response.data : null;
        if (response.status === 200) {
            return content;
        } else {
            throw content;
        }
    }

    authorisationHeader(acceessTocken: string) {
        return {
            headers: {
                Authorization: acceessTocken
            }
        };
    }

    handleError(error: AxiosError) {
        if (error?.response?.status == 500) {
            this.notificationService.warning("API error", "Try again later, or contact WWS");
        }
        if (error?.response?.status == 400) {
            this.notificationService.warning("Somethis went wrong", "Please check configuration or your file");
        }
        return null;
    }


    // api/archive
    async getUserArchivedDocuments(paging: any) {
        return await this.apiPost('archive/getDocuments', paging);
    }

    async deleteArchiveDocument(documentId: any) {
        return await this.apiPost(`archive/deleteDocument/${documentId}`);
    }

    async archiveDocument(documentId: any) {
        return await this.apiPost(`archive/archiveDocument/${documentId}`);
    }


    // api/authentication
    async getUserInfo() {
        return await this.apiGet('authentication/userData');
    }

    async userLogin(acceessTocken: string ) {
        const params = this.authorisationHeader(acceessTocken);
        return await this.apiGet('authentication/login', params);
    }

    async userLogout() {
        return await this.apiGet('authentication/logout');
    }


    // api/configuration
    async getUserSettins() {
        return await this.apiGet('configuration/userSettings');
    }

    async saveTranslationConfig(config: any) {
        return await this.apiPost('configuration/saveTranslationConfig', config);
    }

    async markTourAsPassed() {
        return await this.apiPost('configuration/markTourAsPassed');
    }

    async skipTourToday() {
        return await this.apiPost('configuration/skipTourToday');
    }

    async hideUploadFileMessage() {
        return await this.apiPost('configuration/uploadFileMessage');
    }

    async userConfigOptions() {
        return await this.apiGet('configuration/userConfigOptions');
    }

    async basicConfigOptions() {
        return await this.apiGet('configuration/basicConfigOptions');
    }

    async getSubSubjectOptions(id: number) {
        return await this.apiGet(`configuration/subSubject/${id}`);
    }


    async updateConfiguration(documentId: Number, config: any) {
        return await this.apiPost('configuration/updateDocumentConfig/' + documentId, config);
    }

    async getTranslationConfig(id: number) {
        return await this.apiGet(`configuration/documentConfig/${id}`);
    }


    // api/document
    async translateDocument(documentId: number, pageNumber: number, pageSize: number, useExternalTranslations: boolean) {
        return await this.apiPost(`document/translateDocument?documentId=${documentId}&pageNumber=${pageNumber}&pageSize=${pageSize}&useExternalTranslations=${useExternalTranslations}`);
    }

    async saveTranslationDrafts(documentId: number) {
        return await this.apiPost(`document/saveTranslationDrafts?documentId=${documentId}`);
    }

    async clearFileTranslations(documentId: number, pageSize: number) {
        return await this.apiPost(`segments/clearTranslations?documentId=${documentId}&pageSize=${pageSize}`);
    }

    async getUserDocuments(paging: any) {
        return await this.apiPost('document/user', paging);
    }

    async deleteDocument(documentId: any) {
        return await this.apiPost(`document/deleteDocument?documentId=${documentId}`);
    }

    async getDocumentsCount() {
        return await this.apiGet('document/getDocumentsCount');
    }


    // api/file
    async postDocument(file: any, config: any) {
        const url = 'file/upload';
        const formData = this.mapToFormData(file, config);

        try {
            const responce = await axios.post(url,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            return this.formatResponse(responce)
        }
        catch (error: any) {
            return this.handleError(error as AxiosError);

        }
    }

    private mapToFormData(file: any, config: any) {
        let formData = new FormData();

        formData = this.mapConfigToFormData(formData, config);
        formData.append('file', file);

        return formData;
    }

    private mapConfigToFormData(formData: FormData, config: any) {
        for (const key in config) {
            if (Array.isArray(config[key])) {
                const value = config[key];
                if (value.length > 0) {
                    formData.append(key, config[key]);                   
                }
            } else {
                formData.append(key, config[key]);
            }
        }

        return formData;
    }

    async downloadDocument(id: number) {
        const url = `file/download/${id}`;

        return axios({
            url,
            method: 'GET',
            responseType: 'blob',
        })
            .then((response: any) => {
                const href = window.URL.createObjectURL(response.data);

                const anchorElement = document.createElement('a');

                anchorElement.href = href;

                // 1) Get the value of content-disposition header
                const contentDisposition =
                    response.headers['content-disposition'];

                console.log('contentDisposition: ', contentDisposition);

                // 2) set the fileName variable to the default value
                const filename = this.getFileNameFromContentDisposition(contentDisposition);

                anchorElement.download = filename;

                document.body.appendChild(anchorElement);
                anchorElement.click();

                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
            })
            .catch(error => {
                console.log('error: ', error);
            });
    }

    getFileNameFromContentDisposition(contentDisposition: string): string {
        // Regular expression to match filename* and filename
        const filenameStarRegex = /filename\*=(?:UTF-8'')?([^;]+)/i;
        const filenameRegex = /filename="?([^;"]+)"?/i;

        // Check for the filename* (UTF-8 encoded)
        let match = contentDisposition.match(filenameStarRegex);
        if (match) {
            const filenameUtf = match[1].trim();

            // Decode the URL-encoded filename
            return decodeURIComponent(filenameUtf);
        }

        // Fall back to the regular filename (not encoded)
        match = contentDisposition.match(filenameRegex);
        if (match) {
            const filename = match[1].trim();

            // Remove any surrounding quotes if present
            return filename.replace(/^["']|["']$/g, '');
        }

        return 'WWS-translation-result'; // Return null if filename is not found
    }


    // api/segments
    async updateTranslationState(translation: any) {
        return await this.apiPost('segments/update', translation);
    }

    async setMerged(mergeSegment: any) {
        return await this.apiPost('segments/setMerged', mergeSegment);
    }

    async getDocumentSegments(settings: any) {
        return await this.apiPost('segments/load',settings);
    }

    async findNextWorkSegment(documentId: number, pageSize: number, segmentId: number) {
        return await this.apiPost(`segments/nextWorkSegment?documentId=${documentId}&pageSize=${pageSize}&segmentId=${segmentId}`);
    }

    // api/translation
    async getTranslation(segment: any) {
        return await this.apiPost('translation/translateSegment', segment);
    }

    async reloadSegmentTranslations(segment: any) {
        return await this.apiPost('translation/reloadSegmentTranslations', segment);
    }

    async saveTranslationStyled(segment: any) {
        return await this.apiPost('translation/addTranslationSegment', segment);
    }

    async updateTranslationStyled(segment: any) {
        return await this.apiPost('translation/updateTranslationSegment', segment);
    }

    async updateSegmentStyles(segment: any) {
        return await this.apiPost('translation/updateTranslationStyle', segment);
    }

    async getTermigology(termins: any) {
        return await this.apiPost('translation/getTerms', termins);
    }

    // api/externalTranslation


    async getExternalTranslationProviders(sourceLanguageId: number, tergetLanguageId: number) {
        return await this.apiGet(`externalTranslation/translationProviders/${sourceLanguageId}/${tergetLanguageId}`);
    }

    async getExternalTranslation(segment: any) {
        const unsafeCall = true;
        return await this.apiPost('externalTranslation/translateSegment', segment, unsafeCall);
    }

    async getExternalTranslationLogs() {
        return await this.apiGet(`externalTranslation/log`);
    }


}
