import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7aab218c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "post" }
const _hoisted_2 = { class: "segmnets-editor-container" }
const _hoisted_3 = { class: "paging" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_backtop = _resolveComponent("el-backtop")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_DocumentActions = _resolveComponent("DocumentActions")!
  const _component_TranslationConfig = _resolveComponent("TranslationConfig")!
  const _component_DocumentStatistics = _resolveComponent("DocumentStatistics")!
  const _component_SegmentEditor = _resolveComponent("SegmentEditor")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_HotkeysHandler = _resolveComponent("HotkeysHandler")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_backtop, {
      right: 40,
      bottom: 40
    }),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      _createVNode(_component_TranslationConfig, {
        modelValue: _ctx.translationConfig,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.translationConfig) = $event)),
        onConfigChanged: _ctx.saveConfig,
        pageNamePrefix: "Current document"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_divider),
          _createVNode(_component_DocumentActions, {
            modelValue: _ctx.translationConfig,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.translationConfig) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["modelValue", "onConfigChanged"]),
      (_ctx.documentStatisticsExist)
        ? (_openBlock(), _createBlock(_component_DocumentStatistics, {
            key: 0,
            documentName: _ctx.documentName,
            documentInformation: _ctx.documentInformation
          }, null, 8, ["documentName", "documentInformation"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createBlock(_component_SegmentEditor, {
          key: _ctx.pageKey + 'N',
          documentConfiguration: _ctx.documentData,
          onReload: _ctx.loadConfigurations
        }, null, 8, ["documentConfiguration", "onReload"])),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_pagination, {
            layout: "prev, pager, next",
            total: _ctx.pagingStore.rowCount,
            "current-page": _ctx.pagingStore.currentPageNumber,
            "default-page-size": _ctx.pagingStore.pageSize,
            onCurrentChange: _ctx.goToPage
          }, null, 8, ["total", "current-page", "default-page-size", "onCurrentChange"])
        ])
      ])
    ])), [
      [_directive_loading, _ctx.documentStore.loading]
    ]),
    _createVNode(_component_HotkeysHandler)
  ]))
}