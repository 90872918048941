<template>
    <div class="row">
        <div :class="[{ show: haveDraft }, 'hidden']">
            <el-tooltip :content="draftSegments"
                        effect="light"
                        placement="right">
                <el-tag type="warning">{{draftTag}}</el-tag>
            </el-tooltip>
        </div>

        <div :class="[{ show: haveTranslated }, 'hidden']">
            <el-tooltip :content="translatedSegments"
                        effect="light"
                        placement="right">
                <el-tag type="success">{{translatedTag}}</el-tag>
            </el-tooltip>
        </div>

        <div :class="[{ show: havePreTranslated }, 'hidden']">
            <el-tooltip :content="preTranslatedSegments"
                        effect="light"
                        placement="right">
                <el-tag>{{preTranslatedTag}}</el-tag>
            </el-tooltip>
        </div>

        <div :class="[{ show: newFile }, 'hidden']">
            <el-tooltip :content="tatalSegments"
                        effect="light"
                        placement="right">
                <el-tag>{{nweFileTag}}</el-tag>
            </el-tooltip>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { DocumentRow } from '@/models/DocumentRow'

    export default defineComponent({
        props: {
            rowData: Object,
        },
        data() {
            return {
            };
        },
        computed: {
            documentRow(): DocumentRow {
                return this.rowData as DocumentRow;
            },
            haveDraft(): boolean {
                return this.documentRow.draftSegments > 0;
            },
            haveTranslated(): boolean {
                return this.documentRow.translatedSegments > 0;
            },
            havePreTranslated(): boolean {
                return this.documentRow.preTranslatedSegments > 0;
            },
            newFile(): boolean {
                return this.documentRow.draftSegments == 0 &&
                    this.documentRow.translatedSegments == 0 &&
                    this.documentRow.preTranslatedSegments == 0
            },
            draftSegments(): string {
                return `${this.documentRow.draftSegments} of ${this.documentRow.totalSegments} segment`
            },
            translatedSegments(): string {
                return `${this.documentRow.translatedSegments} of ${this.documentRow.totalSegments} segment`
            },
            preTranslatedSegments(): string {
                return `${this.documentRow.preTranslatedSegments} of ${this.documentRow.totalSegments} segment`
            },
            tatalSegments(): string {
                return `${this.documentRow.totalSegments} segments`
            },
            getTranslatedPersentage(): number {
                const percentage = this.documentRow.totalSegments === 0 ?
                    0 : Math.floor(this.documentRow.translatedSegments / this.documentRow.totalSegments * 100);
                return percentage;
            },
            getDraftPersentage(): number {
                const percentage = this.documentRow.totalSegments === 0 ?
                    0 : Math.floor(this.documentRow.draftSegments / this.documentRow.totalSegments * 100);
                return percentage;
            },
            getPreTranslatedPersentage(): number {
                const percentage = this.documentRow.totalSegments === 0 ?
                    0 : Math.floor(this.documentRow.preTranslatedSegments / this.documentRow.totalSegments * 100);
                return percentage;
            },
            draftTag(): string {
                return `Draft: ${this.getDraftPersentage}%`
            },
            translatedTag(): string {
                return `Translated: ${this.getTranslatedPersentage}%`
            },
            preTranslatedTag(): string {
                return `Pre translated: ${this.getPreTranslatedPersentage}%`
            },
            nweFileTag(): string{
                return '0%'
            },
        },
    })
</script>
